<!--
 * @Author: 张阳帅
 * @Date: 2024-09-23 17:52:37
 * @LastEditTime: 2024-11-13 14:34:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\sever\deptSource.vue
-->
<template>
  <div class="appoint">
    <van-tabs v-model="active" @click="onTabsClick">
      <van-tab v-for="(tabItem,tabIndex) in dateList" :key="tabIndex">
        <template v-if="tabItem.resourceDate" #title> 
          <div :class="tabItem.canAppoint ? 'tabs_title' : 'tabs_title_none' ">
            {{ (tabItem.resourceDate).substring(10, 5) }}
            <div>{{ tabItem.resourceWeek }}</div>
          </div>
        </template>
        <template v-else #title>
          全部
        </template>
        <van-list class="appoint_list"> 
          <van-cell v-for="(item, currentIndex) in currentData" :key="currentIndex">
            <div class="list_item">
              <div class="item_info" @click="onNavClick(item)">
                <div v-if="active==0" class="item_type">
                  <!-- 有号 -->
                  预约挂号
                </div>
                <!-- <div class="item_type_1" v-else>约满</div> -->
                <img class="item_img" :src="item.docImage?item.docImage:'https://lanzhoutech.oss-cn-shanghai.aliyuncs.com/naokeWebImages/h5/public_doctor_info.png'">
                <div class="item_cont">
                  <div class="item_name">
                    {{ item.docName }}  <a class="item_docTitle">{{ item.docTitle }}</a>
                  </div>
                  <!-- <div class="item_deptName">
                    {{ item.deptName }}
                  </div> -->
                </div>
              </div>
              <div class="item_desc">
                <!-- <van-collapse v-model="activeNames">
              <van-collapse-item title="标题1" name="1">内容</van-collapse-item>
              <van-collapse-item title="标题2" name="2">内容</van-collapse-item>
              <van-collapse-item title="标题3" name="3">内容</van-collapse-item>
            </van-collapse> -->

                <!-- <van-collapse v-model="activeNames">
                  <van-collapse-item title="擅长" name="1">
                   
                  </van-collapse-item>
                </van-collapse> -->

                擅长:{{ item.docGoodAt }}
              </div>
              <div v-for="(dataItem, dutyIndex) in item.dutyDataList" :key="dutyIndex" class="item_date">
                <div class="date_list" @click="seleteDateAction(dataItem, item)">
                  <div class="list_dutyDate">
                    {{ dataItem.dutyDate }}
                  </div>
                  <div class="list_deptName">
                    {{ dataItem.dutyTypeName }}
                  </div>
                  
                  <div v-if="dataItem.remainingCount>0" class="item_remainingCount">
                    余号：{{ dataItem.remainingCount }}
                  </div>
                  <div v-if="dataItem.remainingCount == 0" class="item_remainingCountFull">
                    已约满
                  </div>
                </div>
                <div class="listPrice">
                  {{ item.deptName }}
                  <div class="pay">
                    {{ dataItem.fee }}元
                  </div>
                </div>
              </div>
            </div>
          </van-cell>
        </van-list>
      </van-tab>
    </van-tabs>
    <van-popup v-model="seleteDateShow" position="bottom" :style="{ height: '60%' }">
      <div class="popup_page">
        <div class="popup_page_header">
          <van-icon class="popup_page_header_cross" name="cross" size="22" @click="seleteDateShow = false" />
          <div class="popup_page_header_title">
            {{ seleteDateDic.dutyDate }}<span style="margin-left: 15px;">{{ seleteDateDic.dutyTypeName }}</span>                    
          </div>
        </div>
        <div class="popup_page_cont">
          <!-- <div class="popup_page_cont_fee">
            诊查费 <a class="popup_page_cont_fee_color">¥{{ seleteDateDic.fee }}</a>
          </div> -->
          <div class="popup_page_time">
            <div class="popup_page_time_item">
              <div v-for="(item, index) in seleteDateDic.timeRangeList" :key="index" class="popup_page_time_item_1">
                <div v-if="index%2==0" :class="item.remainingCount>0 ? 'popup_page_time_cell' : 'popup_page_time_cell_Full' " @click="getGjcRegisterAction(item)"> 
                  {{ item.timeBegin }}~{{ item.timeEnd }}   
                  <div v-if="item.remainingCount>0" class="popup_page_time_cell_color">
                    余号：{{ item.remainingCount }}
                  </div>
                  <div v-else class="popup_page_time_cell_color">
                    已约满
                  </div>
                </div>
              </div>
            </div>
            <div class="popup_page_time_item">
              <div v-for="(item, index) in seleteDateDic.timeRangeList" :key="index" class="popup_page_time_item_1">
                <div v-if="index%2!=0"
                     :class="item.remainingCount>0 ? 'popup_page_time_cell_1' : 'popup_page_time_cell_Full' " @click="getGjcRegisterAction(item)"
                >
                  {{ item.timeBegin }}-{{ item.timeEnd }}  
                  <div v-if="item.remainingCount>0" class="popup_page_time_cell_color">
                    余号：{{ item.remainingCount }}
                  </div>
                  <div v-else class="popup_page_time_cell_color">
                    已约满
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {gjcModel} from '../../../api/gjcApi'

export default {
    data(){
        return{
            dateList:[], //日期列表
            currentData:[], //数据列表
            active: 1,
            deptCode:'',
            docCode:'',
            currentNewDate:'',
            seleteDateDic:{}, //选择时间段
            seleteDocDic:{}, //选择医生
            seleteDateShow:false,  //选择时间段显示
            activeNames:['1']
        }
    },
    mounted() {
        this.getQueryRegisterData('first')
    },
    methods:{
        //获取号源列表 type:first(第一次获取)
        getQueryRegisterData(type){
            const params = {
                hospCode: '',
                docCode: this.docCode,
                currentDate:this.currentNewDate,
                deptCode: this.$route.query.deptCode
            }
            gjcModel.getQueryRegisterData(params).then((result) => {
                console.log('号源列表', result)
                this.dateList = result.data.dateList
                this.currentData = result.data.currentData
                if(type == 'first'){
                    this.onTabsClick(1)
                }
            })
        },
        onTabsClick(name){
            const item = this.dateList[name]
            this.currentNewDate = item.resourceDate
            //console.log('切换日期', this.currentNewDate)
            this.getQueryRegisterData()
        },
        //选择医生时间
        seleteDateAction(item, docDic){
            console.log('选择医生时间', item)
            this.seleteDateDic = item
            this.seleteDocDic = docDic
            this.seleteDateShow = true
        },
        //选择医生时间段
        getGjcRegisterAction(item){
          if(item.remainingCount == 0){
            return
          }
            console.log('号源列表-选择医生时间段', item)
            this.$router.push({
                path: '/gjcRegister',
                query: {
                    seleteTimeDic:JSON.stringify(item),
                    seleteDateDic:JSON.stringify(this.seleteDateDic),
                    seleteDocDic:JSON.stringify(this.seleteDocDic)
                }
            })
        },
        //医生详情
        onNavClick(item){
            console.log('选择医生', item)
            this.$router.push({
                path: '/docMain',
                query: {
                    docCode:String(item.docCode),
                    deptCode:String(item.deptCode)
                    // currentDate:this.currentData
                }
            })
        }
    }
}
</script>
<style scoped lang='less'>
.page-wrap{
  background: #f5f5f5;
}
 /deep/.van-tab__text--ellipsis{
      display: block;
      width: 100%;
      text-align: center;
    }
    .tabs_title{
        text-align: center;
        width: 100%;
        color: #222b31;
    }
    .tabs_title_none{
        text-align: center;
        width: 100%;
        color: #b0b8bd;
    }
    .page_deptName{
        padding: 40px 24px 40px 24px;
        font-size: 32px;
        background: #eee;
    }
    .appoint_list{
        padding: 20px 0 0 0;
        // background-color: aqua;
    }
    // .list_item{
    //     // background-color: aqua;
    // }
    .item_info{
        display: flex;
        align-items: center;
        // background-color: aqua;
    }
    .item_img{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: rgb(205, 205, 205);
    }
    .item_cont{
        display: flex;
        flex-direction: column;
        margin-left: 24px;
    }
    .item_name{
        font-size: 32px;
        color: #333;
        font-weight: bold;
    }
    .item_docTitle{
        margin-left: 24px;
        font-size: 30px;
        color: #333;
        font-weight: normal;
    }
    .item_deptName{
        font-size: 26px;
        color: #333;
    }
    .item_desc{
        font-size: 26px;
        color: #666;
        display:-webkit-box;
        overflow:hidden;
        margin-left: 100px;
        padding: 0 20px;
        text-overflow:ellipsis;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:1;
        line-clamp: 1; /* 添加标准属性line-clamp以兼容 */
    }
    .item_type{
        position: absolute;
        right: 0px;
        // margin-top: 30px;
        // width: 100px;
        height: 40px;
        text-align: center;
        color: #fff;
        font-size: 28px;
        line-height: 40px;
        background: #1E8DFF;
        border-radius: 12px;
        padding: 10px;
    }
    .item_type_1{
        position: absolute;
        right: 0px;
        margin-top: 30px;
        width: 100px;
        height: 40px;
        background-color: #aaa;
        text-align: center;
        color: #333;
        font-size: 28px;
        line-height: 40px;
    }
    .item_date{
        border-radius: 12px;
        padding: 24px 0;
    }
    .date_list{
        display: flex;
        
        align-items: center;
    }
    .list_dutyDate{
        font-size: 32px;
        color: #333;
    }
    .list_deptName{
        margin-left: 24px;
        font-size: 32px;
        color: #333;
    }
    .list_fee{
        position: absolute;
        right: 190px;
        font-size: 32px;
        color: #f80404;
    }
    .item_remainingCount{
        position: absolute;
        right: 0;
        height: 50px;
        line-height: 50px;
        border-radius: 12px;
        background-color: #1e80ff;
        color: white;
        font-size: 28px;
        text-align: center;
        padding: 10px 20px;
        margin-top: 26px;
    }
    .item_remainingCountFull{
      position: absolute;
        right: 30px;
        height: 50px;
        line-height: 50px;
        border-radius: 12px;
        background-color: #EEF1F5;
        color: #9a9a9a;
        font-size: 28px;
        text-align: center;
        padding: 10px 30px;
        margin-top: 26px;
    }
    .listPrice{
      display: flex;
      align-items: center;
      color: #8c8d99;
      font-size: 30px;
      .pay{
        color: #f80404;
        padding: 0 40px;
      }
    }
    .popup_page{
        background-color: #fff;
    }
    .popup_page_header{
        display: flex;
        // justify-content: center;
    }
    .popup_page_header_title{
        width: 100%;
        // margin: 20px 0;
        // padding: 20px 100px;
        font-size: 32px;
        color: #222b31;
        text-align: center;
        line-height: 40px;
        padding: 30px;
        background: #f8f8f8;
    }
    .popup_page_header_cross{
        position: absolute;
        margin: 20px 0;
        right: 20px;
    }
    .popup_page_cont{
        background-color: #fff;
    }
    .popup_page_cont_fee{
        width: 100%;
        font-size: 30px;
        color: #333;
        text-align: center;
        line-height: 60px;
    }
    .popup_page_cont_fee_color{
        color: #f80404;
    }
    .popup_page_time{
        display: flex;
        justify-content: space-between;
    }
    .popup_page_time_item{
        width: 50%;
    }
    .popup_page_time_cell{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin: 20px 10px 20px 20px;
        background: rgba(30,141,255,0.08);
        border-radius: 12px;
        padding: 10px 20px 10px 20px;
        font-size: 26px;
        line-height: 50px;
        color: #1e8dff ;
        font-weight: 500;
      font-family: PingFangSC-Regular;
    }
    .popup_page_time_cell_1{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin: 20px 20px 20px 10px;
        background: rgba(30,141,255,0.08);
        border-radius: 12px;
        padding: 10px 20px 10px 20px;
        font-size: 26px;
        line-height: 50px;
        color: #1e8dff ;
        font-weight: 500;
    }
    .popup_page_time_cell_Full{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin: 20px 20px 20px 10px;
        background: #eef1f5;
        border-radius: 12px;
        padding: 10px 20px 10px 20px;
        font-size: 26px;
        line-height: 50px;
        color: #9c9fad ;
        font-weight: 500;
    }
    .popup_page_time_cell_color{
        color: #6a6d78;
        font-size: 26px;
    }
   /deep/.van-tabs__line{
      background: #1e8dff !important;
    }
</style>
